<template>
<div class='integrationsJuvonno'>
	<div class='field'>
		<TextInput 
			v-model='tenant' 
			maxlength='60' 
			:placeholder='$t("views.integrations.juvonno.phTenant")' 
			:class='{ err }'
			@input='err = false'
		/>
	</div>
	<div class='field'>
		<TextInput 
			v-model='apiKey' 
			maxlength='100' 
			:placeholder='$t("views.integrations.juvonno.phApiKey")' 
			:class='{ err }'
			@input='err = false'
		/>
	</div>
</div>
</template>

<script>
import TextInput from '@/components/common/TextInput'

export default {
	name: 'IntegrationsJuvonno',
	components: { TextInput },
	data() {
		return {
			err: false,
			apiKey: '',
			tenant: ''
		}
	},
	methods: {
		get() { return { apiKey: this.apiKey, tenant: this.tenant }},
		showErr() { 
			this.err = true 
		},
		reset() { 
			this.apiKey = '' 
			this.tenant = ''
		}
	}
}
</script>

<style lang='scss'>
.integrationsJuvonno {
}
</style>
